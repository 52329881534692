#overviewDiv {
  position: absolute;
  bottom: 40px;
  right: 30px;
  width: 200px;
  height: 100px;
  border: 1px solid black;
  z-index: 1;
  overflow: hidden;
}

#extentDiv {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 2;
}
